<template>
  <section id="patient-form-base">
    <!-- seach banner  -->
    <v-card
      flat
      class="patient-form-base-bg d-flex align-center justify-center text-center mb-7"
    >
      <v-card-text id="patientViewFile">
        <p class="mb-7 tw-text-lg tw-text-white">
          <span v-if="PatientProfile['FIRST NAME'] != null"> Patient Selected: </span>
          <v-icon
            v-if="PatientProfile['FIRST NAME'] != null"
            class="me-1"
            color="white"
          >
            {{ icons.mdiAccountHeart }}
          </v-icon>
          <span
            v-if="PatientProfile['FIRST NAME'] != null"
            class="tw-text-xl text-no-wrap"
          >
            {{ patientFullName }}
          </span>
        </p>

        <v-form class="kb-search-input mx-auto">
          <v-text-field
            v-model="knowledgeBaseSearchQuery"
            outlined
            placeholder="Search for form..."
            hide-details
            class="kb-search-input"
          >
            <template #prepend-inner>
              <v-icon
                size="23"
                class="mx-1"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
            </template>
          </v-text-field>
        </v-form>
      </v-card-text>
    </v-card>
    <div id="patient-form-base-content">
      <v-row class="kb-search-content-info match-height">
        <v-col
          v-for="item in filteredHubList"
          v-show="item.env == 'prod' || (item.env == 'dev' && ($store.state.User.pharmacy._id == '5b539d500d356d3568fddad2') || $store.state.User.pharmacy._id == '5c86a701e18d087fc087192f')"
          :key="item.icon"
          md="3"
          sm="6"
          cols="12"
          class="kb-search-content"
        >
          <v-card
            class="hover:tw-bg-gray-400 hover:tw-bg-opacity-5 "
            color="text-center cursor-pointer"
            :data-intercom-target="item.dataIntercomTarget"
            @click.native="$router.push({
              name: item.page,
            })"
          >
            <div class="tw-flex tw-justify-start">
              <div
                v-if="item.Status"
                class="tw-badge tw-badge-info"
              >
                {{ item.Status }}
              </div>
            </div>
            <div class="card-wrapper">
              <v-img
                contain
                :max-width="item.iconSize"
                class="mx-auto align-center justify-center"
                :src="item.icon"
              ></v-img>
            </div>

            <!-- title -->
            <v-card-title class="justify-center pb-4 tw-break-normal">
              {{ item.title }}
            </v-card-title>
            <!-- <v-card-text>{{ item.desc }}</v-card-text> -->
          </v-card>
        </v-col>

        <!-- no result found -->
        <v-col
          v-show="!filteredHubList.length"
          cols="12"
          class="text-center"
        >
          <h4 class="mt-4">
            Search result not found!!
          </h4>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
/* eslint-disable implicit-arrow-linebreak */
import { computed, ref } from '@vue/composition-api'
import { mdiMagnify, mdiAccountHeart } from '@mdi/js'
import { mapGetters, mapState } from 'vuex'

import { dataIntercomTarget } from '@/assets/formSchema/intercomTargets'
import formHubList from './formHubList'

export default {
  name: 'FormHub',
  computed: {
    ...mapGetters(['UserProfile', 'PharmacyProfile', 'PatientProfile']),
    ...mapState(['User'], ['PatientProfileStore']),
  },
  components: {},
  setup() {
    const knowledgeBaseSearchQuery = ref('')
    const filteredHubList = computed(() => {
      const knowledgeBaseSearchQueryLower = knowledgeBaseSearchQuery.value.toLowerCase()

      return formHubList.filter(
        item =>
          // eslint-disable-next-line operator-linebreak
          item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower),

        // item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) ||
        // item.desc.toLowerCase().includes(knowledgeBaseSearchQueryLower),
      )
    })

    return {
      knowledgeBaseSearchQuery,
      filteredHubList,
    }
  },
  data() {
    return {
      formHubList,
      patientFullName: '',
      dataIntercomTarget,
      icons: {
        mdiMagnify,
        mdiAccountHeart,
      },
    }
  },

  mounted() {
    this.changeNameCase()

    this.$root.$on('Refresh_Status_Bar', patientID => {
      this.changeNameCase()
      this.preloadForm()
    })
    this.$root.$on('Refresh_Table', patientID => {
      this.changeNameCase()
      this.preloadForm()
    })
  },
  methods: {
    capitalizeFirstLetter(str) {
      return str.toLowerCase().charAt(0).toUpperCase() + str.toLowerCase().slice(1)
    },
    changeNameCase() {
      if (this.PatientProfile['FIRST NAME'] != null) {
        this.patientFullName = `${this.capitalizeFirstLetter(
          this.PatientProfile['FIRST NAME'],
        )} ${this.capitalizeFirstLetter(this.PatientProfile['LAST NAME'])}`
      }
    },
    preloadForm() {
      this.patientData = {
        ...this.$store.state.Patient.data,
      }
      this.medicationsList = this.$store.state.Patient.medications
      this.patientProfile = {
        ...this.$store.state.Patient.data,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/formHub.scss';

#patientViewFile {
  span.font-weight-black.text-no-wrap {
    font-weight: 900 !important;
    font-size: 24px !important;
  }

  span.pharmacyName {
    font-weight: 900 !important;
    font-size: 16px !important;
  }
}
</style>
