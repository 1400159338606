export const dataIntercomTarget = {
  patientSearch: 'appBarPatientSearch',
  appBarPatientSearchButton: 'appBarPatientSearchButton',
  appBarPatientSearchDialog: 'appBarPatientSearchDialog',
  patientSearchModalLastName: 'patientSearchModalLastName',
  patientSearchModalFirstName: 'patientSearchModalFirstName',
  patientSearchModalPAtientSelect: 'patientSearchModalPAtientSelect',
  patientformsFormCardOpinion: 'patientformsFormCardOpinion',
  PrescriptionList: 'PrescriptionList',
  DoctorList: 'DoctorList',
  opinionButtonNext: 'opinionButtonNext',
  opinionRadioDtpPatientNEedsAdditionalDrugTherapy: 'opinionRadioDtpPatientNEedsAdditionalDrugTherapy',
  opinionRadioUrgencyMedium: 'opinionRadioUrgencyMedium',
  OpinionTextCommentary: 'OpinionTextCommentary',
  OpinionTextRecommendation: 'OpinionTextRecommendation',
  opinionButtonNext2: 'opinionButtonNext2',
  opinionSigningPad: 'opinionSigningPad',
  opinionButtonSubmit: 'opinionButtonSubmit',
}

export const dataIntercomTargetNavBar = {}

export const formList = [
  'adaptPrescription',
  'renewPrescription',
  'pharmaceuticalOpinion',
  'medsCheck',
  'mdBlisterPackChart',
  'drugInteractions',
  'verbalPrescription',
  'fentanylDocumentation',
  'vacationSupply',
  'frequentDispensingAssessment',
  'letterTemplate',
  'covid19RapidAntigenTest',
  'earlyRefill',
  'fluConsentForm',
  'newRxChecklist',
  'refillRxChecklist',
  'frequentDispensingReAssessment',
  'greenShieldFrequentDispensing',
  'assureFrequentDispensing',
  'nutritionalProduct',
]

export const HomeINtercomTarget = [
  'homeBanner',
  'formHub',
  'dashboardHub',
  'groupHub',
  'homeFeatureCardA',
  'homeFeatureCardB',
  'homeFeatureCardC',
]
