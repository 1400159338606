const formHubList = [{
  icon: require('@/assets/images/icons/Pharmacy_Spray Bottle.png'),
  iconSize: '60',
  title: 'Adapt Prescription',
  dataIntercomTarget: 'adaptPrescription',
  desc: 'A pharmacist may adapt a prescription based upon the individual circumstances of the patient by altering the dose, dosage form, regimen or route of administration to address the patient’s unique needs and circumstances.',
  page: 'form-adapt',
  env: 'prod',
},
{
  icon: require('@/assets/images/icons/Pharmacy_Receipt-05.png'),
  iconSize: '60',
  title: 'Renew Prescription',
  dataIntercomTarget: 'renewPrescription',
  desc: 'A pharmacist may renew a prescription if the medication to be continued is for the purpose of continuity of care. ',
  page: 'form-renew',
  env: 'prod',
},
{
  icon: require('@/assets/images/icons/Pharmacy_Bill.png'),
  iconSize: '60',
  title: 'Pharmaceutical Opinion',
  dataIntercomTarget: 'pharmaceuticalOpinion',
  desc: 'The Pharmaceutical Opinion program refers to the identification by the pharmacist of a potential drug related problem during the course of dispensing a new or repeat prescription or when conducting a MedsCheck medication review.',
  page: 'form-opinion',
  env: 'prod',
},
{
  icon: require('@/assets/images/icons/Doctor_Consultation.png'),
  iconSize: '60',
  title: 'Verbal Prescription',
  dataIntercomTarget: 'verbalPrescription',
  desc: 'Use this form to quickly generate documentation for Verbal Prescriptions called in by Doctors offices',
  page: 'form-verbalrx',
  env: 'prod',
},
{
  icon: require('@/assets/images/icons/Pharmacy_Poison.png'),
  iconSize: '60',
  title: 'Fentanyl Documentation',
  dataIntercomTarget: 'fentanylDocumentation',
  desc: 'Patients who receive a prescription for fentanyl must return their used patches to a pharmacy before receiving new ones. ',
  page: 'form-fentanyl',
  env: 'prod',
},
{
  icon: require('@/assets/images/icons/Pharmacy_Medicine Capsule.png'),
  iconSize: '60',
  title: 'MD Blister Pack Chart',
  dataIntercomTarget: 'mdBlisterPackChart',
  desc: 'Compliance packaging refers to the dispensing of medications in a clearly organized and labeled blister pack',
  page: 'form-bpgrid',
  env: 'prod',
},
{
  icon: require('@/assets/images/icons/vacationSupply.png'),
  iconSize: '60',
  title: 'Vacation Supply',
  dataIntercomTarget: 'vacationSupply',
  page: 'form-vacation',
  env: 'prod',
  desc: 'For most people in the Ontario Drug Benefit Program (ODB), if you travel outside the province for between 60 and 200 days, and as long as your prescription allows it, the ministry may pay for a travel supply of medication of up to an additional 60 days.',
},
{
  icon: require('@/assets/images/icons/Rehabilitation_Nurse.png'),
  iconSize: '60',
  title: 'Frequent Dispensing Assessment',
  dataIntercomTarget: 'frequentDispensingAssessment',
  desc: 'Documentation when patients require a more frequent medication dispensing interval to help him/her achieve desired health outcomes.',
  page: 'form-frequentassessment',
  env: 'prod',
},
{
  icon: require('@/assets/images/icons/Pharmacy_6.png'),
  iconSize: '60',
  title: 'Letter Template',
  dataIntercomTarget: 'letterTemplate',
  desc: 'Documentation necessary to prevent Third-Party Payors clawbacks',
  page: 'form-letter',
  env: 'prod',
},

{
  icon: require('@/assets/images/icons/Pharmacy_Lab.png'),
  iconSize: '60',
  title: 'COVID-19 Rapid Antigen Test',
  dataIntercomTarget: 'covid19RapidAntigenTest',
  desc: 'Documentation necessary to prevent Third-Party Payors clawbacks',
  page: 'form-rapidantigen',
  env: 'prod',
},
{
  icon: require('@/assets/images/icons/Pharmacy_Sleep Medicine.png'),
  iconSize: '60',
  title: 'Drug Interactions',
  dataIntercomTarget: 'drugInteractions',
  desc: 'Use the Drug Interactions Checker to pre-load all your Patients medications and identify all interactions with one click.',
  page: 'form-interactions',
  env: 'prod',
},

{
  icon: require('@/assets/images/icons/PT_A.png'),
  iconSize: '60',
  title: 'Frequent Dispensing Re-Assessment',
  dataIntercomTarget: 'frequentDispensingReAssessment',
  desc: 'Regular assessment is required to verify the ongoing need for more frequent dispensing, and to determine if the patient is stabilized and capable of managing 100 day supplies',
  page: 'form-reassessment',
  env: 'prod',
},
{
  icon: require('@/assets/images/icons/Icon Color_14.png'),
  iconSize: '60',
  Status: 'Dev Only',
  title: 'Template',
  dataIntercomTarget: 'template',
  desc: 'Template',
  page: 'form-template',
  env: 'dev',
},

  // {
  //   icon: require('@/assets/images/pharmacyIcons/Pharmacy_Online_Pharmacy.png'),
  //   iconSize: '60',
  //   title: 'MedsCheck',
  //   dataIntercomTarget: 'medsCheck',
  //   desc: 'The MedsCheck program is a one-to-one consultation with your patients for approximately 20 - 30 minutes once a year to help them comply with their prescription medications and better understand how the medications interact with each other and other over-the-counter medication they may be taking.',
  //   page: 'patient-form-medscheck',
  //   env: 'prod',
  // },

  // {
  //   icon: require('@/assets/images/pharmacyIcons/EarlyRefill.png'),
  //   iconSize: '60',
  //   title: 'Early Refill',
  //   Status: 'Coming Soon',
  //   dataIntercomTarget: 'earlyRefill',
  //   desc: 'Documentation necessary to prevent Third-Party Payors clawbacks',
  //   page: 'patient-form-early-refill',
  //   env: 'prod',
  // },
  // {
  //   icon: require('@/assets/images/pharmacyIcons/Icon Color_10.png'),
  //   iconSize: '60',
  //   title: 'Flu Consent Form',
  //   Status: 'Coming Soon',
  //   dataIntercomTarget: 'fluConsentForm',
  //   desc: 'A document with important information about a medical procedure or treatment, a clinical trial, or genetic testing. It also includes information on possible risks and benefits.',
  //   page: 'patient-form-flu-consent',
  //   env: 'prod',
  // },
  // {
  //   icon: require('@/assets/images/pharmacyIcons/Pharmacy_Receipt-12.png'),
  //   iconSize: '60',
  //   title: 'New Rx Checklist',
  //   Status: 'Coming Soon',
  //   dataIntercomTarget: 'newRxChecklist',
  //   desc: 'A document with important information about a medical procedure or treatment, a clinical trial, or genetic testing. It also includes information on possible risks and benefits.',
  //   page: 'patient-form-newrx',
  //   env: 'prod',
  // },
  // {
  //   icon: require('@/assets/images/pharmacyIcons/Sick Leave Policy.png'),
  //   iconSize: '60',
  //   title: 'Refill Rx Checklist',
  //   Status: 'Coming Soon',
  //   dataIntercomTarget: 'refillRxChecklist',
  //   desc: 'A document with important information about a medical procedure or treatment, a clinical trial, or genetic testing. It also includes information on possible risks and benefits.',
  //   page: 'patient-form-refillrx',
  //   env: 'prod',
  // },

  // {
  //   icon: require('@/assets/images/logos/gs.png'),
  //   iconSize: '60',
  //   title: 'GreenShield Frequent Dispensing',
  //   Status: 'Coming Soon',
  //   dataIntercomTarget: 'greenShieldFrequentDispensing',
  //   desc: 'Documentation when patients require a more frequent medication dispensing interval to help him/her achieve desired health outcomes.',
  //   page: 'compliance-green-shield',
  //   env: 'prod',
  // },
  // {
  //   icon: require('@/assets/images/logos/assureLogo.png'),
  //   iconSize: '60',
  //   title: 'Assure Frequent Dispensing',
  //   Status: 'Coming Soon',
  //   dataIntercomTarget: 'assureFrequentDispensing',
  //   desc: 'Documentation when patients require a more frequent medication dispensing interval to help him/her achieve desired health outcomes.',
  //   page: 'compliance-assure',
  //   env: 'prod',
  // },
  // {
  //   icon: require('@/assets/images/pharmacyIcons/Pharmacy_Kids Medicine-29.png'),
  //   iconSize: '60',
  //   title: 'Nutritional Product',
  //   Status: 'Coming Soon',
  //   dataIntercomTarget: 'nutritionalProduct',
  //   page: 'patient-form-nutritional',
  //   env: 'prod',
  //   desc: 'In some cases, the Ontario Drug Benefit (ODB) Program covers nutrition products. An Ontario doctor must prescribe the products as the patient’s sole source of nutrition.',
  // },
]

export default formHubList
